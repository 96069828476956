body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
  width: 100vh;
  height: 100%;
  z-index: 1;
  border-radius: 20px;
}

.geologica-bold-inline {
  font-family: geologica-bold;
}

@font-face {
  font-family: 'geologica-black';
  src: url('./styles/fonts/Geologica-Black.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'geologica-bold';
  src: url('./styles/fonts/Geologica-Bold.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'geologica-extra-light';
  src: url('./styles/fonts/Geologica-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'geologica-regular';
  src: url('./styles/fonts/Geologica-Regular.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'red-hat-regular';
  src: url('./styles/fonts/RedHatDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'red-hat-black';
  src: url('./styles/fonts/RedHatDisplay-Black.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'red-hat-bold';
  src: url('./styles/fonts/RedHatDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'inconsolata-regular';
  src: url('./styles/fonts/Inconsolata-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInOut50 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn20 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes fadeIn50 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}